<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-tabs>
      <v-tab @click="receive_end=false">{{ $t('container.receive_step') }}</v-tab>
      <v-tab @click="receive_end=true">{{ $t('container.receive_end') }}</v-tab>
    </v-tabs>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.receive')"
      class="px-5 py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.total_cubic_feet="{ item }">
          {{ fixedFloat(item.total_cubic_feet, 2, 0) }}
        </template>
        <template v-slot:item.export="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportReceiptSheets(item)"
              >
                {{ $t("receipt_sheet") }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewItem(item)"
              >mdi-eye</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewItem(item)"
              >mdi-message-text-outline</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip> -->
          
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/container/container/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.order_number"),
        value: "order_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_cubic_feet"),
        value: "total_cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "cutoff_date",
      },
      {
        sortable: false,
        text: vm.$i18n.t("export"),
        value: "export",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    selected: [],
    receive_end: false,
  }),
  mixins: [BaseTable],
  components: {
    CommonAlertDialog,
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "ReceiveDetail",
        query: {
          container: item.id,
          name: item.container_number,
          stuffing: item.stuffing_date,
        },
      });
    },
    exportReceiptSheets(item) {
      const year = item.stuffing_date.split('-')[0]
      const month = item.stuffing_date.split('-')[1]
      const day = item.stuffing_date.split('-')[2]
      const stuffing_date = year + "年" + month + "月" + day + "日"
      const url = "/container/container/" + item.id + "/getReceiptSheets/";
      this.getFileApi(url, "領貨單_"+stuffing_date);
    },
  },
  watch: {
    receive_end(val){
      if (val==true) {
        this.getParams["status"] = 5
        delete this.getParams["status__lte"]
      } else {
        this.getParams["status__lte"] = 4
        delete this.getParams["status"]
      }
      this.getApi();
    },
  },
  mounted() {
    this.getParams["status__lte"] = 4
    this.options = {
      sortBy: ["id"],
      sortDesc: [false],
    };
  }
};
</script>